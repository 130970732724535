











import Vue from 'vue'

import deepFreeze from 'deep-freeze'
import log from '@/log'

import IssuesCard from '@/components/issues/IssuesCard.vue'

import Issue from '@/models/Issues/Issue'

const DEFAULT_DENSE = true

export default Vue.extend({
  metaInfo: {
    title: 'Issues',
  },
  components: {
    IssuesCard,
  },
  props: {
    dense: {
      type: Boolean,
      default: DEFAULT_DENSE,
    },
  },
  data: () => ({
    issues: [] as ReadonlyArray<Issue>,

    showResolved: false,

    sortBy: ['createdDate'],
    sortDesc: [true],

    isFetching: false,
    errors: [] as Error[],

    searchDialog: false,

    disablePagination: false,
    hideDefaultFooter: false,
  }),
  computed: {
    search(): string {
      const q = this.$route.query.q || ''
      return Array.isArray(q) ? q.join('') : q
    },
    filteredIssues(): ReadonlyArray<Issue> {
      if (this.showResolved) return this.issues

      return this.issues.filter(z => !z.resolvedDate)
    },
  },
  methods: {
    setIssues(issues: Issue[]): void {
      this.issues = deepFreeze(issues)
    },
    refreshIssues(): void {
      this.fetchIssues()
    },
    onSearchInput(v: string): void {
      const queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.q = v || ''
      this.$router.replace({ query: queries })
    },
    onSave(item: Issue): void {
      const index = this.issues.findIndex(z => z.id === item.id)

      if (index >= 0) {
        this.setIssues([
          ...this.issues.slice(0, index),
          item,
          ...this.issues.slice(index + 1),
        ])
      } else {
        this.setIssues([...this.issues, item])
      }
    },
    onDelete(itemId: number): void {
      const index = this.issues.findIndex(z => z.id === itemId)

      if (index >= 0) {
        this.setIssues([
          ...this.issues.slice(0, index),
          ...this.issues.slice(index + 1),
        ])
      }
    },
    fetchIssues(): void {
      this.isFetching = true
      this.errors = []

      const query = Object.entries(this.$route.query).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: value,
        }),
        {}
      )

      this.$api.issues
        .list(query)
        .then(issues => {
          this.setIssues(issues)
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
  },
  mounted() {
    this.fetchIssues()
  },
})
